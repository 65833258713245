<template>
  <div>
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">
          Leaderboard
        </p>
        <p class="subtitle">
          Movies and Filmmaking Community Ranking
        </p>
      </div>
    </section>
      <div class="container mt-3">

      <div class="columns is-justify-content-center is-multiline">
        <div
          class="column is-7"
          v-for="(user, i) in users"
          :key="i">
          <div class="box">
            <div class="columns is-vcentered">
              <div class="column is-3 is-2-fullhd">
                <figure class="image is-128x128">
                  <b-image :src="user.settings.clappyImageUrl || 'https://cdn.discordapp.com/attachments/796094456129388544/933696117969944606/file.jpg'"></b-image>
                </figure>
              </div>
              <div class="column ml-2">
                <strong><span class="is-size-4">#{{ i + 1 }}</span> {{ user.username }}</strong>
                <p>Level {{ numberWithSpaces(user.settings.level) }}</p>
                <p>XP {{ numberWithSpaces(user.settings.currentXp) }} / {{ numberWithSpaces(neededXp(user.settings.level)) }} ({{ Math.floor((user.settings.currentXp * 100) / neededXp(user.settings.level)) }}%)</p>
                <p>Has {{ numberWithSpaces(user.settings.tickets) }} tickets</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <infinite-loading @infinite="loadMore" spinner="spiral" class="pb-4">
        <template slot="no-more">All users loaded.</template>
        <div slot="no-results">No users found.</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { getLeaderboard } from '@/api/guild'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading
  },
  data: () => ({
    users: [],
    page: 1
  }),
  methods: {
    neededXp (level) {
      return 5 * level ** 2 + 50 * level + 100
    },
    numberWithSpaces (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async loadMore ($state) {
      await this.fetchUsers()

      $state.loaded()
      if (this.page === 0) $state.complete()
    },
    async fetchUsers () {
      const users = await getLeaderboard(this.page)
      this.users.push(...users)

      if (users.length < 100) this.page = 0 // all loading done
      else this.page++
    }
  }
}
</script>

<style>

</style>
