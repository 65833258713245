import request from '@/utils/request'

export const getGuild = async () => {
  return request({
    url: '/guild',
    method: 'get'
  })
}

export const getMembers = async () => {
  return request({
    url: '/guild/members',
    method: 'get'
  })
}

export const updateGuild = async (data) => {
  return request({
    url: '/guild',
    method: 'put',
    data
  })
}

export const updateRoles = async (data) => {
  return request({
    url: '/guild/roles',
    method: 'put',
    data
  })
}

export const contact = async (data) => {
  return request({
    url: '/guild/contact',
    method: 'post',
    data
  })
}

export const banAppeal = async (data) => {
  return request({
    url: '/guild/ban-appeal',
    method: 'post',
    data
  })
}

export const getPremiums = async (page) => {
  return request({
    url: `/guild/premiums?page=${page}`,
    method: 'get'
  })
}

export const getLeaderboard = async (page) => {
  return request({
    url: `/guild/leaderboard?page=${page}&limit=100`,
    method: 'get'
  })
}
